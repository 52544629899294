@import "variables";
@import "colors";
// BEGIN: Utility classes.  Note the use of !important, so can override any built-in styles on the specified elements
// scss-lint:disable ImportantRule

// creating .margin-bottom, .margin-top, .padding-bottom, etc. utility classes
// pattern is: mb-1em = 1em, 5em = .5em, 75em = .75em
// Moving towards a pattern like described here: https://getbootstrap.com/docs/4.0/utilities/spacing/
$margins: (
        0: 0,
        25em: .25em,
        5em: .5em,
        75em: .75em,
        1em: 1em,
        15em: 1.5em,
        2em: 2em,
        auto: auto
);

$sides: (
        top: t,
        right: r,
        bottom: b,
        left: l
);

$font-sizes: (
        5em: .5em,
        6em: .6em,
        7em: .7em,
        8em: .8em,
        9em: .9em,
        1em: 1em,
        11em: 1.1em,
        12em: 1.2em,
        13em: 1.3em,
        14em: 1.4em,
        15em: 1.5em,
        75em: 1.75em,
        2em: 2em,
        25em: 2.5em
);

@each $name, $size in $font-sizes {
  .fs-#{$name} {
    font-size: $size;
  }
}

@each $name, $em in $margins {
  @each $side, $abbr in $sides {
    .m#{$abbr}-#{$name} {
      margin-#{$side}: unquote("#{$em}") !important;
    }

    .p#{$abbr}-#{$name} {
      padding-#{$side}: unquote("#{$em}") !important;
    }
  }
}

.block {
  display: block !important;
}

.none {
  display: none !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-blue {
  color: $ui-blue !important;
}

.hide-on-desktop {
  @media only screen and (min-width: $tablet) {
    display: none !important;
  }
}

.hide-on-tablet {
  @media only screen and (max-width: $tablet) {
    display: none !important;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;

  .col {
    box-sizing: border-box;
  }

  @for $i from 1 through 6 {
    .col-#{$i} {
      flex: 0 0 percentage(1 / $i);
      max-width: percentage(1 / $i);
    }
  }

  @media only screen and (max-width: $tablet) {
    flex-flow: column;

    .col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.grid-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;


  .col {
    box-sizing: border-box;
  }

  $columns: 12;

  @for $i from 1 through $columns {
    .col-#{$i} {
      flex: 0 0 percentage($i / $columns);
      max-width: percentage($i / $columns);
    }
  }

  @media only screen and (max-width: $tablet) {
    flex-flow: column;

    .col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
