@import "../../colors";

.wrapper {
  display: flex;
  position: relative;
  z-index: 3;
  padding: .75rem;
  background-color: $ui-dark-blue;
  color: $white;

  > * {
    margin: 0;
  }

  > h3 {
    flex-grow: 1;
    margin-left: 10px;
    text-align: center;
  }

  > nav {
    margin-right: .75rem;
  }
}

.disableTouch {
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.menuToggle {
  display: block;
  z-index: 4;
  top: 50px;
  left: 50px;
  -webkit-user-select: none; // scss-lint:disable VendorPrefix
  user-select: none;

  input {
    display: none;
    position: absolute;
    z-index: 3; // place it over the hamburger
    top: -5px;
    left: -5px;
    width: 100px;
    height: 55px;
    opacity: 0;
    cursor: pointer;
    -webkit-touch-callout: none;

    &:checked ~ ul {
      transform: none;
    }
  }
}

.menu {
  position: absolute;
  z-index: 3;
  top: -100px;
  bottom: 0;
  left: 0;
  height: 100vh;
  padding: 130px 50px 50px 20px;
  list-style-type: none;
  transform: translate(-100%, 0);
  transform-origin: 0 0;
  transition: transform .5s cubic-bezier(.77, .2, .05, 1);
  background: $ui-dark-blue;
  color: $white;
  -webkit-font-smoothing: antialiased;

  a {
    text-decoration: none;
  }

  li {
    padding: 5px 0;
    color: $white;
    font-size: 20px;
    font-weight: 500;
  }
}

.imageFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 210px;

  > p {
    margin-left: 15px;
  }

  > img {
    flex-shrink: 0;
  }
}

.ham {
  position: absolute;
  z-index: 10;
  top: 50%;
  margin: 0 -15px 0 -10px;
  transform: translateY(-50%);
  transform-origin: center;
  transition: transform 400ms;
  stroke: $white;
  cursor: pointer;
  // scss-lint:disable VendorPrefix
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  // scss-lint:enable VendorPrefix
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.hamRotate.active {
  transform: rotate(45deg) translateY(-90%);
}

.line {
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke-width: 5.5;
  stroke-linecap: round;
  stroke: $white;
  fill: none;
}

// hamburger menu icon classes
.top {
  stroke-dasharray: 40, 139;
}

.bottom {
  stroke-dasharray: 40, 180;
}

.active .top {
  stroke-dashoffset: -98px;
}

.active .bottom {
  stroke-dashoffset: -138px;
}

.logoWrapper {
  flex-grow: 1;
  width: 100%;
  margin-left: 10px;
  text-align: center;

  > a {
    img,
    h3 {
      vertical-align: middle;
    }

    img {
      display: inline-block;
      max-width: 40px;
    }

    h3 {
      display: inline-block;
      margin: 0 0 0 15px;
      color: $white;
    }
  }

  > img,
  h3 {
    vertical-align: middle;
  }
}
