@import "utilities";
@import "extends";
@import "colors";
@import "variables";

html,
body {
  margin: 0;
  border: none;
  outline: 0;
  font-family: 'Libre Franklin', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  color: $ui-black;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root { // scss-lint:disable IdSelector
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

#modal { // scss-lint:disable IdSelector
  @media only screen and (min-width: $tablet+1px) {
    display: none;
  }

  position: absolute;
  top: 0;
  width: 100%;

  $spacing: 30px;

  .modal-container {
    position: fixed;
    z-index: 9999999;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    transition: opacity .3s ease-in-out;
    animation: fade-in .3s;
    background: $ui-dark-blue;
    color: $white;
  }

  .modal-content {
    position: relative;
    z-index: 1;
    padding: $spacing / 2;
  }

  .close {
    display: none;
    position: absolute;
    z-index: 2;
    top: $spacing / 4;
    right: $spacing / 4;
    cursor: pointer;
  }

  &:not(:empty) {
    z-index: 99999;
    height: 100%;
  }
}

#nav-sticky {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.smaller {
  font-size: smaller;
}

.bold {
  font-weight: bold;
}

.hide {
  display: none !important; // scss-lint:disable ImportantRule
}

.iPhoneX-pb {
  padding-bottom: env(safe-area-inset-bottom);
}

.rounded-button {
  @extend %button;
}

@each $name, $color in $colors {
  .#{$name} {
    color: $color !important; //scss-lint:disable ImportantRule
  }

  .fill-lighten-#{$name} {
    fill: lighten($color, 30%);
  }

  .fill-#{$name} {
    fill: $color;
  }

  .bg-#{$name} {
    background-color: $color;
  }

  .stroke-#{$name} {
    stroke: $color;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
