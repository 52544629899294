@import "../../colors";
@import "../../variables";

.basicInfoContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-right: -.75em;
  margin-left: -.75em;
  padding: 40px 10px;
  background: $ui-blue;
  color: $white;
  font-size: 1.2rem;
  text-align: center;

  > div {
    text-align: center;
  }

  h3 {
    margin-top: 0;
  }

  p {
    margin-top: 0;
  }

  > :first-child {
    flex-grow: 1;
  }

  > :last-child {
    //display: inline-flex;
    //align-items: center;
  }

  .compareCheckBox {
    text-align: center;

    label {
      color: $white;
      font-size: 1em;
    }
  }
}

.navSchoolName {
  flex-grow: 1;
  text-align: right;

  > a {
    text-decoration: none;
  }
}

.tourStep {
  background: $white;
}

.mainContainer {
  .actionBar {
    margin-right: -.75em;
    margin-left: -.75em;
  }

  &[class*="__tour1__"] {
    background: $white;
  }
}

.percentile {
  margin: 1rem auto;
  color: $gray;
}

.historyChartContainer {
  display: flex;
  justify-content: space-around;
  max-width: 100%;
  height: 200px;
  padding: 20px 10px;
  background: $light-gray;
}

.infoWrapper {
  background: $white;
}

.historyDescription {
  margin-bottom: 0;
  padding-bottom: 1em;
  color: $gray;
  font-size: 1.1rem;
}

@keyframes grow-height {
  from {
    max-height: 0;
  }

  to {
    max-height: 400px;
  }
}

.expand,
%expand {
  overflow: hidden;
  animation: grow-height .8s ease;
}

.detailContainer {
  @extend %expand;
  margin: 0 auto;
  padding: 1rem .5rem;
  background-color: $light-gray;
}

.sectionHeaderWrapper {
  background: $ui-dark-blue;
  color: $white;

  p {
    margin: 0;
    padding: .5rem 0;
    color: $white;
    font-size: .8rem;
    text-align: center;
    text-transform: uppercase;
  }
}

.gridRow {
  display: flex;
  background-color: $white;
}

.cellWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5px;
  text-align: center;

  p {
    margin: .5rem auto;
    font-size: 1.5rem;
    font-weight: bold;
  }

  h5 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: normal;
  }
}

.selected {
  position: relative;

  .elementHighlight {
    border-color: $ui-blue;
  }

  .detailArrowWrapper {
    background: $ui-blue;
    color: $white;
  }
}

.leftCell {
  padding-right: 0;
}

.detailArrowWrapper {
  padding: 15px 10px;
  background: $light-gray;
  color: $ui-blue;
  font-size: .8rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  span {
    margin-right: .25rem;
  }

  img {
    height: 10px;
    margin: 0;
    transition: .25s;
  }
}

.spinArrow {
  transform: rotate(180deg);
}

// TODO: This should be uniform....
.profileNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: $ui-dark-blue;
  color: $ui-white;
  text-align: center;

  .navChevron,
  .navFullProfile,
  .navFullProfile a {
    color: $ui-white;
    text-decoration: none;
  }

  .navChevron {
    margin: auto 0;
    font-size: 1rem;
    font-weight: bold;

    > i {
      font-size: 2rem;
      vertical-align: middle;
    }

    > span {
      display: inline-block;
      margin-left: 10px;
      text-transform: uppercase;
      vertical-align: middle;
    }
  }

  .navFullProfile {
    margin: auto 20px auto 0;
    padding: 10px 0;
    font-size: 2rem;
    text-align: center;

    i {
      margin: 0 auto;
    }
  }

  .navFullProfileText {
    margin: 0;
    padding: 0;
    font-size: .8rem;
    text-decoration: none;
  }
}

.surveyLevel,
%surveyLevel {
  margin: .25rem auto;
  font-weight: normal;
}

.activeSurveyLevel {
  @extend %surveyLevel;
  font-size: 1.75rem;
  font-weight: bold;
}

.actionBar {
  display: flex;
  align-items: center;
  background: $ui-blue;
  color: $white;

  > * {
    width: 50%;
    padding: 8px 20px;
    line-height: 1.2rem;
    vertical-align: middle;
  }
}

.compareCheckBox {
  text-align: right;

  > input {
    display: inline-block;
    margin: 0 5px;
    vertical-align: middle;
  }

  label {
    display: inline-block;
    font-weight: bold;
    vertical-align: middle;
    cursor: pointer;
  }
}

.legend {
  color: $gray;

  span + span {
    float: right;
  }
}

.svgContainer {
  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.schoolProfileAccordion {
  // scss-lint:disable ImportantRule
  position: relative;
  margin: 0 0 5px !important;
  box-shadow: none !important;

  .accordionWrapper {
    position: relative;
    padding: 1em;
  }

  .accordionHeader {
    margin: 0;
    font-size: 1.2rem;

    .undefined {
      display: none;
    }
  }

  .overlayBackground {
    background: $ui-blue;
  }
}

.elementHighlight {
  padding: 20px 10px;
  border: 2px solid $light-gray;

  h5 {
    margin-bottom: 20px;
    color: $gray;
  }
}

.accordionsToggler {
  display: block;
  padding: 15px 10px;
  color: $ui-blue;
  font-weight: bold;
  text-align: right;
  text-transform: uppercase;

  > span {
    display: inline-block;
    cursor: pointer;
  }

  i {
    margin-left: 5px;
  }
}

.profileRow {
  padding: 20px;

  > [class^="col"] {
    padding: 15px;
  }

  @media only screen and (min-width: $tablet + 1px) {
    > :first-child {
      padding-top: 0;
      padding-left: 0;
    }
  }

  @media only screen and (max-width: $tablet) {
    > :first-child {
      padding: 15px 0 0;
    }

    > :last-child {
      order: -1;
    }
  }
}

.borderedBlock {
  border: 1px solid $light-gray;
}

.program {
  display: block;

  + .program {
    margin-top: 5px;
  }
}
