@import "../../colors";
@import "../../variables";
@import "../../utilities";
@import "../../extends";

// TODO: This file is PURPOSELY not a SCSS module inorder to override top level modal styles

#modal { // scss-lint:disable IdSelector
  background: rgba($black, .35);

  &.show {
    display: block !important; // scss-lint:disable ImportantRule

    .close {
      display: block;
      top: 15px;
      right: 15px;
    }
  }

  &:not(:empty) {
    position: fixed;
    z-index: 99999999999;
  }
}

.popup-container {
  position: absolute;
  z-index: 9999999999;
  top: 50%;
  left: 50%;
  width: 75vw;
  padding: 30px;
  transform: translate(-50%, -50%);
  background: $white;
  text-align: center;

  .button {
    @extend %button;
  }

  @media only screen and (min-width: $phone) {
    max-width: 600px;
    padding: 50px 100px;
  }

  @media only screen and (max-width: $phone) {
    max-width: calc(100% - 20px);

    p {
      &:last-child {
        span {
          display: block;

          + span {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
