@import "../../colors";
@import "../../extends";

.overlay {
  position: fixed;
  z-index: 90;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: absolute;
  top: 20vh;
  left: 50%;
  width: 80%;
  max-width: 500px;
  padding: 15px 15px;
  transform: translateX(-50%);
  animation: fade-in 0.2s ease-out;
  border-radius: 8px;
  background-color: $white;

  .body {
    max-height: 50vh;
    overflow-y: auto;
  }

  .school {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 10px;

    + .school {
      border-top: 1px solid $gray;
    }

    :first-child {
      flex-grow: 1;
    }
  }

  .footer {
    display: flex;
    justify-content: space-around;
    padding: 15px 15px;
  }
}

.modalButton {
  padding: 8px 10px;
  border: 2px solid $ui-blue;
  border-radius: 10px;
  color: $ui-blue;
  font-size: 1em;
  text-decoration: none;
  cursor: pointer;

  &.invert {
    background-color: $ui-blue;
    color: $white;
  }
}

.disabled {
  label {
    color: $gray ! important;
  }
}

.error,
.message {
  margin: .5rem 0 1rem;
  padding: .75rem 1.25rem;
  border: 1px solid #ebcccc;
  border-radius: .25rem;
  background: #f2dede;
  color: #a94442;
}

.message {
  border-color: #484;
  background: #efe;
  color: #060;
}

.checkboxLabel {
  color: $ui-blue;
  font-size: .85rem;
  text-transform: uppercase;
}

.button {
  @extend %button;
}

.resultItem {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 1rem 0;
  border-bottom: 1px solid $whitesmoke;
  background-color: $white;
  color: unset;
  text-decoration: unset;

  > :nth-child(1) {
    flex: 1;
  }

  > :nth-child(2) {
    flex: 4;
  }

  > :nth-child(3) {
    flex: 1;
  }
}

.leftMain {
  display: flex;
}

.totalScore {
  flex-shrink: 0;
  margin-right: 1rem;
  text-align: center;
}

.schoolDetail {
  margin: .25rem auto 0;
  font-size: .8rem;
}

.remove {
  display: block;
  margin: -.25rem auto 0;
  cursor: pointer;

  label {
    display: block;
    color: $ui-blue;
  }
}

.controls {
  margin-bottom: 1em;
  padding: 1em 0 1em 1em;
  background: $bg-gray;

  label {
    margin-right: 1em;
  }

  select {
    font-size: 14px;
  }
}

@keyframes fade-in {
  from {
    top: 20%;
    bottom: 40%;
  }

  to {
    top: 30%;
    bottom: 30%;
  }
}

.search_location {
  box-sizing: border-box;
  width: 94vw;
  margin: 20px auto;
  padding: 10px;
  outline: none;
  font-size: 14px;
}

.addAnotherSchool {

  .fa {
    display: inline-block;
    width: 44px;
    height: 44px;
    margin-right: 1em;
    margin-left: calc(1em + 4px);
    border: 3px solid $light-gray;
    border-radius: 50%;
    color: $ui-blue;
    text-align: center;
    line-height: 46px;
  }

  :nth-child(2) {
    color: $ui-blue;
    font-size: 18px;
    font-weight: bold;
    line-height: 46px;
  }
}

.removeBasicLinkStyles {
  color: inherit;
  text-decoration: none;
}
