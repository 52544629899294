@import "../../colors";
@import "../../variables";

$mercury-gray: #e1e1e1;
$gallery-gray: #eee;

.wrapper {
  background: $gallery-gray;

  > .button {
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;

    a {
      color: $ui-blue;
      text-decoration: none;
    }
  }

  @media only screen and (min-width: $tablet + 1px) {
    text-align: center;

    > .button {
      display: inline-block;
      padding: 10px 15px;

      a {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        align-items: center;
      }

      i {
        padding-left: 10px;
      }

      + .button {
        margin-left: 15px;
      }
    }
  }

  @media only screen and (max-width: $tablet) {
    display: flex;
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    flex-grow: 0;
    justify-content: space-between;
    width: 100%;
    // for iPhone X users
    // @help https://css-tricks.com/the-notch-and-css/
    padding-bottom: env(safe-area-inset-bottom);
    border-top: 2px solid $ui-blue;
    background-color: $white;
  }
}

.button {
  @media only screen and (max-width: $tablet) {
    flex: 1 1;
    width: 25%;
    padding: 15px 5px;
    background: linear-gradient(180deg, rgba($mercury-gray, 1) 0%, rgba($white, 1) 100%);
    font-size: 13.2px;

    a {
      color: unset;
      text-decoration: unset;

      i {
        margin-bottom: .5em;
        color: $ui-blue;
        font-size: 1.8em;
      }

      p {
        margin: 0 auto;
        color: $ui-blue;
      }
    }

    + .button {
      border-left: 1px solid $white;
    }
  }
}
