@import "../../../../variables";
@import "../../../../colors";

.cardHeader {
  margin-top: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid;
  text-transform: uppercase;
}

.uiBlueColor {
  color: $ui-blue !important; // scss-lint:disable ImportantRule
}

.highScore {
  color: $sqrp-green;
}

.averageScore {
  color: $sqrp-yellow;
}

.lowScore {
  color: $sqrp-red;
}

.scoreGroup {
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 15px;

  + .scoreGroup {
    margin-top: 15px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $ui-blue;
  }
}

.scoreGroupIcon {
  width: 50px;
  font-size: 30px;

  > i {
    vertical-align: top;
  }
}

.metrics {
  display: flex;
  flex-flow: column nowrap;

  > span {
    display: block;
    color: $gray;
    font-weight: bold;
  }
}
