@import "../../colors";
@import "../../extends";
@import "../../variables";
@import "../../utilities";

$border-radius: 40px;

.overlay {
  position: fixed;
  z-index: 90;
  width: 100vw;
  height: 100vh;
  background-color: rgba($black, .5);
}

.modal {
  position: absolute;
  top: 20vh;
  left: 50%;
  width: 80%;
  max-width: 500px;
  padding: 15px;
  transform: translateX(-50%);
  animation: fade-in .2s ease-out;
  border-radius: 8px;
  background-color: $white;

  .body {
    max-height: 50vh;
    overflow-y: auto;
  }

  .school {
    margin: 0;
    padding: 10px;

    + .school {
      border-top: 1px solid $gray;
    }
  }

  .footer {
    display: flex;
    justify-content: space-around;
    padding: 15px;
  }
}

.modalButton {
  padding: 8px 10px;
  border: 2px solid $ui-blue;
  border-radius: 10px;
  color: $ui-blue;
  font-size: 1em;
  text-decoration: none;
  cursor: pointer;

  &.invert {
    background-color: $ui-blue;
    color: $white;
  }
}

.disabled {
  label {
    color: $gray !important; //scss-lint:disable ImportantRule
  }
}

.error {
  margin: .5rem 0 1rem;
  padding: .75rem 1.25rem;
  border: 1px solid #ebcccc;
  border-radius: .25rem;
  background: #f2dede;
  color: #a94442;
}

.checkboxLabel {
  color: $ui-blue;
  font-size: .85rem;
  text-transform: uppercase;
}

.button {
  @extend %button;

  &.whiteOutline {
    &,
    &BlueBackground {
      border: 2px solid $white;
      border-radius: 0;
      background: $ui-blue;
      font-weight: normal;
      text-transform: none;

      &:hover {
        border-color: $white;
        background: $white;
        color: $ui-blue;
      }
    }

    &BlueBackground {
      border: 2px solid $white;
      background: $ui-blue;
      color: $white;

      &:hover {
        border-color: $white;
        background: $white;
        color: $ui-blue;
      }
    }
  }
}

.resultItem {
  display: flex;
  justify-content: space-between;
  margin: .25rem 0;
  padding: 1rem 0;
  border-bottom: 1px solid $whitesmoke;
  background-color: $white;
  color: unset;
  text-decoration: unset;

  :nth-child(1) {
    flex: 1;
  }

  :nth-child(2) {
    flex: 4;
  }

  :nth-child(3) {
    flex: 1;
  }
}

.leftMain {
  display: flex;
}

.totalScore {
  flex-shrink: 0;
  //margin-right: 1rem;
  //margin-left: 1rem;
  text-align: left;
}

.schoolDetail {
  margin: .25rem auto 0;
  font-size: .8rem;

  &.schoolAddress {
    text-transform: capitalize;
  }
}

.controls {
  padding: 1em 0 2em 1em;

  label {
    margin-right: 1em;
  }

  select {
    font-size: 14px;
  }
}

@keyframes fade-in {
  from {
    top: 20%;
    bottom: 40%;
  }

  to {
    top: 30%;
    bottom: 30%;
  }
}

.search_location {
  box-sizing: border-box;
  width: 94vw;
  margin: 0 auto;
  padding: 10px;
  border: 2px solid darken($light-gray, 5);
  outline: none;
  font-size: 14px;
}

.isChecked {
  + label {
    // scss-lint:disable ImportantRule
    border-color: $white !important;
    background: $ui-blue !important;
    color: $white !important;
  }
}

.groupTabs {
  display: flex;
  margin: 0 auto;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  > input {
    display: none;

    + label {
      box-sizing: border-box;
      flex-grow: 1;
      margin-right: -2px;
      padding: 0.75rem 1rem;
      border: 2px solid $white;
      background: transparent;
      color: $white;
      font-size: .75rem;
      font-weight: 400;
      text-align: center;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }

    &[checked="checked"] {
      + label {
        &::after {
          content: "\f058";
          margin-left: 5px;
          font-family: FontAwesome, sans-serif;
        }
      }
    }
  }

  > input:checked + label {
    border-color: $white;
    background: $ui-blue;
    color: $white;
  }

  &.withoutIcons {
    text-transform: capitalize;

    input {
      + label {
        font-size: 1em;
        font-weight: bold;
      }

      &:checked {
        + label {
          &::after {
            content: none;
          }
        }
      }
    }
  }
}

.count {
  margin-left: 1em;
  font-weight: bold;
}

.compareWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputWrapper {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 15px 0 10px;
  border: 2px solid darken($light-gray, 5);
  border-radius: $border-radius;
  background-color: $white;
  color: $black;

  input:focus {
    outline: 0;
  }

  input,
  select {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    height: 36px;
    padding: 8px 0;
    border: none;
    outline: none;
    background: transparent;
    font-size: 15px;
  }

  select {
    appearance: none;
  }

  i {
    &.fa {
      margin-left: -20px;
      color: $sqrp-red;
    }
  }
}

.labelSelectWrapper {
  display: flex;
  align-items: center;
}

.removeBasicLinkStyles {
  color: inherit;
  text-decoration: none;
}

.stickyNav {
  display: block;
  z-index: 25;
  box-sizing: border-box;
  width: 100%;
  background: $ui-blue;
  color: $white;

  span,
  a {
    display: inline-block;
    cursor: pointer;

    + a,
    + span {
      margin-left: 15px;
    }
  }

  @media only screen and (max-width: $tablet) {
    padding: 7px 10px;
  }
}

.filterWrapper {
  background: $ui-dark-blue;
  color: $white;
}

.paragraphStyles {
  margin-top: 0;
}

.filteredSchools {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  // Weird work around for SCSS modules
  > [id="map"] {
    flex: 1;
  }

  @media only screen and (max-width: $tablet) {
    padding-bottom: 82px;
  }
}

$tabs-desktop-height: 71px;
$nav-desktop-height: 64px;

.mainContentWrapper {
  display: flex;
  position: relative;
  flex-flow: column;
  flex-grow: 1;

  @media only screen and (min-width: $tablet + 1px) {
    max-height: calc(100vh - #{$tabs-desktop-height + $nav-desktop-height});
  }
}

.mapFilters {
  display: flex;
  position: absolute;
  z-index: 1;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  background: $ui-dark-blue;
  color: $white;

  .desktopFiltersNav {
    padding: 10px;
  }
}

.desktopFilterNav {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid $white;

  > :first-child {
    flex-grow: 1;
  }

  [class*="button"] {
    + [class*="button"] {
      margin-left: 15px;
    }
  }

  span {
    > div {
      display: inline-flex;
      margin-left: 10px;
    }
  }
}

.desktopFilters {
  span {
    + span {
      margin-left: 25px;
    }
  }
}

.desktopSearch {
  display: flex;
  position: relative;
  flex-grow: 1;
  overflow: hidden;

  > .filteredSchools {
    position: relative;
    width: calc(100% - 450px);
    margin-left: auto;
    padding: 15px;
  }
}

.desktopSearchList {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  width: 450px;
  padding: 15px;
  overflow: hidden;

  > .filteredSchools {
    padding: 10px;
    overflow-y: auto;

    > .resultItem {
      margin: 0;
      padding-right: 10px;
      padding-left: 10px;

      &:hover {
        background: $light-gray;
      }
    }
  }

  > .filterWrapper {
    padding: 10px 20px;
  }
}

.mapFiltersRow {
  > [class^="col"] {
    padding: 20px 0;

    + [class^="col"] {
      border-top: 1px solid $white;
    }
  }

  p,
  h3 {
    margin-top: 0;
  }

  @media only screen and (min-width: $tablet + 1px) {
    flex-grow: 1;
    overflow: hidden;
    overflow-y: auto;

    > [class^="col"] {
      padding: 20px;

      + [class^="col"] {
        border: none;
        border-left: 1px solid $white;
      }
    }
  }
}

.mapLoader {
  display: block;
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background: rgba($ui-blue, .8);

  > .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    color: $white;
    //font-size: 20em;
  }
}
