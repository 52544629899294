@import "../../../../colors";
@import "../../../../utilities";

$one-fifth: percentage(1 / 5);

.scoreFilters {
  display: block;
}

.score {
  display: block;
  padding: 5px;

  input {
    cursor: pointer;

    &:checked {
      + label {
        opacity: 1;
      }
    }
  }

  label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }

  svg {
    margin: 0 !important; // scss-lint:disable ImportantRule
    vertical-align: middle;

    + span {
      display: inline-block;
      margin-left: 5px;
    }
  }

  [class^="stroke-sqrp"] {
    fill: $white;
  }
}
