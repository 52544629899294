@import "../../variables";
@import "../../colors";

%link-pseudo-element {
  &::after {
    content: "\f064";
    display: inline-block;
    margin-left: 10px;
    font-family: FontAwesome, sans-serif;
  }
}

.blueFooterLink {
  // scss-lint:disable ImportantRule
  border-color: $ui-blue;
  background: $ui-blue !important; //scss-lint:disable
  color: $white !important;

  > a {
    color: $white;
  }
}

.roundButton {
  @extend %link-pseudo-element;
  display: inline-block;
  padding: 20px 40px;
  border: 1px solid $ui-blue;
  border-radius: 5px;
  background: $ui-blue;
  color: $white;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
}
