@import "../../colors";
@import "../../variables";

.wrapper {
  position: relative;
  // should be less than tour overlay and tooltip
  z-index: 90;
  height: 300px;
  margin: 2em auto;
}

.fixedCenter {
  position: fixed;
  top: 10%;
  right: 10%;
  bottom: 10%;
  left: 10%;
  height: auto;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 125px;
  margin: 0 auto;
  transform: translate(-50%, -50%);

  @media only screen and (min-width: $phone + 1px) {
    max-width: 250px;
  }
}

.blue {
  .loading-path {
    stroke: $ui-blue;
  }
}

.circular-loader {
  //position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  // scss-lint:disable VendorPrefix
  -ms-transform-origin: center center;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: rotate 2s linear infinite;
  // scss-lint:enable VendorPrefix
  animation: rotate 2s linear infinite;
}

.loader-path {
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;

  .blue & {
    stroke: $ui-blue;
  }

  .white & {
    stroke: $white;
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes color {
  0% {
    stroke: $ui-blue;
  }

  40% {
    stroke: $ui-blue;
  }

  66% {
    stroke: $ui-blue;
  }

  80%,
  90% {
    stroke: $ui-blue;
  }
}
