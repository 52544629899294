$footer-background-color: #002d5b;
$white: #fff;

.wrapper {
  padding-top: 2em;
  padding-bottom: 100px;
  background: $footer-background-color;
  color: $white;
  text-align: center;
}

.container {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 20px;

  > img {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
}

.widget {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding: 0 10px;
  vertical-align: top;

  &:nth-child(1),
  &:nth-child(2n+2) {
    text-align: left;
  }

  &:nth-child(2n+1) {
    text-align: right;
  }
}
