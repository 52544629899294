@import "../../colors";
@import "../../variables";

$spacing: 10px;
$border-radius: 5px;

%link-pseudo-element {
  &::after {
    content: "\f064";
    display: inline-block;
    margin-left: 10px;
    font-family: FontAwesome, sans-serif;
  }
}

@mixin headings() {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @content;
  }
}

.content-block {
  overflow: hidden;
  border: 1px solid $light-gray;
  border-radius: $border-radius;
  text-align: center;

  @include headings() {
    margin-top: 0;
  }

  p {
    margin-top: 0;
  }

  h4 {
    font-weight: normal;

    &:first-child {
      font-weight: bold;
    }
  }

  + & {
    margin-top: 10px;
  }

  a {
    color: $ui-blue;
    text-decoration: none;
  }
}

.main-content {
  padding: $spacing * 3;

  :last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  a {
    @extend %link-pseudo-element;
  }
}

.footer-content {
  padding: $spacing * 3;
  border-top: 1px solid $light-gray;

  :last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &.footer-link {
    padding: $spacing * 2;
    background: $light-gray;
    color: $ui-blue;

    a {
      @extend %link-pseudo-element;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

div {
  + .content-block {
    margin-top: $spacing;
  }
}
