@import "../../../../colors";
@import "../../../../variables";

.selectGroup {
  margin-bottom: 10px;

  .select {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;

    input,
    label {
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
    }

    input {
      margin: 0;

      + label {
        margin-top: -3px;
        padding-left: 10px;
      }
    }

    + .select {
      margin-top: 12px;
    }
  }

  > .select {
    + .select {
      margin-top: 20px;
    }
  }

  .selectSubGroup {
    padding: 15px 0 15px 20px;
  }

  + .selectGroup {
    margin-top: 15px;
  }
}
