// colors.scss
// this file should ONLY contain scss color variables
// this file is used as an import target across each component's scss module
// IMPORTANT: defining classes here will cause those classes to be duplicated in each locally scoped module

$white: #fff;
$black: #000;
$yellow: #fabe31;

$transparent: rgba(0, 0, 0, 0);
$white-text: #fff;
$bg-gray: #ebebeb;
$light-gray: rgba(238, 238, 238, 1);
$whitesmoke: rgba(200, 200, 200, .8);
$gray: #808080;
$dove-gray: #646464;
$gray-text: $dove-gray;
$shadow-gray: rgba(32, 33, 36, .28);

$ui-white: #fff;
$ui-blue: #13b1df;
$ui-dark-blue: #002d5b;
$ui-black: #1b1b26;
$ui-gray: #9595a7;

// SQRP rating colors
$sqrp-red: rgb(200, 20, 20);
$sqrp-light-red: rgb(249, 0, 7);
$sqrp-yellow: rgb(250, 190, 49);
$sqrp-light-green: rgb(60, 215, 60);
$sqrp-green: rgb(50, 178, 50);
$sqrp-gray: #646464;

// create map in order to loop over and create utility classes in index.scss
$colors: (
        sqrp-red: $sqrp-red,
        sqrp-light-red: $sqrp-light-red,
        sqrp-yellow: $sqrp-yellow,
        sqrp-light-green: $sqrp-light-green,
        sqrp-green: $sqrp-green,
        sqrp-gray: $sqrp-gray,
        black: $black,
        gray: $gray,
        gray-text: $gray-text,
        light-gray: $light-gray,
        white-text: $white-text,
        white: $white-text,
        transparent: $transparent
);
