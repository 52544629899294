@import "../../../../variables";
@import "../../../../colors";

.accordion {
  margin: 0;
  border: 2px solid $white;
  border-radius: 4px;
  background: transparent;

  + .accordion {
    margin-top: 15px;
  }

  &[class$='open'] {
    [class*='accordionTabHeaderWrapper'] {
      background: $ui-blue;
    }

    > :first-child {
      background: $ui-blue;
    }
  }
}

.accordionTabHeaderWrapper {
  padding: 15px;
  cursor: pointer;

  [class*='Overlay'] {
    display: none;
  }
}

.accordionTabHeader {
  align-items: center;
  margin: 0;

  > :first-child {
    flex-grow: 1;
    padding-right: 15px;
  }
}

.accordionBody {
  padding: 20px 15px 15px;
  border-top: 2px solid $white;
}

.filterControls {
  @media only screen and (max-width: $tablet) {
    display: flex;

    > span {
      width: 50%;
      font-size: 1em;
      font-weight: bold !important; //scss-lint:disable ImportantRule
    }

    > :first-child {
      margin-right: 5px;
    }

    > :last-child {
      margin-left: 5px;
    }
  }
}
