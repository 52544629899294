@import '../../colors';

.detailCard {
  padding: 5px 8px;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  border-radius: 4px;
  margin: 10px;

  h4,
  h3 {
    margin: 0.5em 0;
  }
  h4 {
    font-size: 90%;
  }
}

.detailBarContainer {
  display: flex;
  align-items: center;
  width: 100%;

  > svg {
    flex-shrink: 0;
  }
}

.circleLogo {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid black;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  font-size: 1.3em;
}

.groupWrapper {
  display: flex;
  align-items: center;

  > strong {
    font-size: 0.9em;
  }
}

.inactive {
  color: rgba(100, 100, 100, 0.5);
  .circleLogo {
    border-color: $gray !important;
  }
}

.groupTabsContainer {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 0.75em;
  grid-column-gap: 0.25em;
  margin-bottom: 1.5em;
}
