@import "../../colors";
@import "../../variables";

.tabItems {
  position: relative;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: lighten($ui-gray, 35);
  }
}

.tabContent {
  padding: 20px 0 0;
}

.tabItem {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 15px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  &:hover,
  &.activeTab {
    color: $ui-blue;
  }

  &.activeTab {
    &::after {
      opacity: 1;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    opacity: 0;
    background: $ui-blue;
  }

  @media only screen and (max-width: $phone) {
    font-size: .85em;
  }
}
