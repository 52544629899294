@import '../../colors';

.card {
  margin: 10px;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0 1px 6px $shadow-gray;

  h2,
  h3,
  p,
  span {
    position: relative;
    z-index: 2;
  }
}

.rotate {
  transform: rotate(180deg);
}

.bg {
  position: relative;
  padding: 1em 1.5em;
  cursor: pointer;
}

.body {
  display: block;
}

.bgImage1 {
  position: relative;
  background-image: url('/assets/images/tool-overview.jpg');
  background-position: 0 -175px;
  background-size: 500px auto;
}

.bgImage2 {
  position: relative;
  background-image: url('/assets/images/parent-circle.jpeg');
  background-position: -100px -50px;
  background-size: 500px auto;
}

.bgImage3 {
  position: relative;
  background-image: url('/assets/images/parent-circle2.jpg');
  background-position: -80px -40px;
  background-size: 500px auto;
}

.container {
  padding: 15px 10px;
  text-align: center;
}

.imageOverlay {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($ui-dark-blue, .66);
}

.headerContent {
  display: flex;
  justify-content: space-between;
  color: $white;
}

.closeAccordion {
  padding: 20px;
  color: $ui-blue;
  font-weight: bold;
  text-align: right;
  text-transform: uppercase;

  i {
    vertical-align: baseline;
  }

  &.mapFilter {
    display: inline-block;
    margin-left: auto;
    color: $white;
    cursor: pointer;
  }
}
