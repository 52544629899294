@import "../../colors";
@import "../../extends";

.container {
  padding: 15px 10px;
  text-align: center;
}

// TODO: Build an extends? Or put this in "Common"?
.buttonLinks {
  @extend %button;
}

.spaceButtons {
  display: flex;
  justify-content: space-around;
}

// for use in 'need help' button
.yellow {
  background-color: $yellow;
}

.helpButtonWrapper {
  margin: 20px auto;
  text-align: center;
}
