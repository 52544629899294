@import "colors";

%button {
  display: inline-block;
  width: fit-content;
  padding: .75rem 1rem;
  border: none;
  border-radius: 40px;
  outline: 0;
  background-color: $ui-blue;
  color: $white;
  font-size: .9rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
