.main-content a::after, .footer-content.footer-link a::after {
  content: "\f064";
  display: inline-block;
  margin-left: 10px;
  font-family: FontAwesome, sans-serif; }

.content-block {
  overflow: hidden;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  text-align: center; }
  .content-block h1,
  .content-block h2,
  .content-block h3,
  .content-block h4,
  .content-block h5,
  .content-block h6 {
    margin-top: 0; }
  .content-block p {
    margin-top: 0; }
  .content-block h4 {
    font-weight: normal; }
    .content-block h4:first-child {
      font-weight: bold; }
  + .content-block {
    margin-top: 10px; }
  .content-block a {
    color: #13b1df;
    text-decoration: none; }

.main-content {
  padding: 30px; }
  .main-content :last-child {
    margin-bottom: 0;
    padding-bottom: 0; }

.footer-content {
  padding: 30px;
  border-top: 1px solid #eeeeee; }
  .footer-content :last-child {
    margin-bottom: 0;
    padding-bottom: 0; }
  .footer-content.footer-link {
    padding: 20px;
    background: #eeeeee;
    color: #13b1df; }
    .footer-content.footer-link a {
      font-weight: bold;
      text-transform: uppercase; }

div + .content-block {
  margin-top: 10px; }
